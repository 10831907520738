import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";
import { SessionService } from "./session.service";

@Injectable()
export class CampaignService {
    private handler = `/msx`;
    private url = `${environment.uri}${this.handler}`;
    constructor(private avcHttp: AvcHttp, private helper: Helper, private sessionService: SessionService) { }

    public getTemplate(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/campaigns/templates").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public save(formData, template, fromName = "", selectedPractices = []): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        let data = JSON.stringify({
            campaign_id: formData.campaignId != null ? formData.campaignId.toLocaleLowerCase() : null,
            group_id: selectedGroup.toLocaleLowerCase(),
            campaign_name: formData.campaignName,
            campaign_description: template.campaign_description === undefined ? formData.campaignName : template.campaign_description,
            campaign_from: fromName,
            subject: formData.subject,
            reply_email: formData.reply,
            preheader: formData.preheader,
            featured_image: template.featured_image,
            html_template: template.html_template,
            test_email: template.test_email === undefined ? "" : template.test_email,
            send_datetime: template.send_datetime == undefined ? null : template.send_datetime,
            member_group_ids: "",
            practice_ids: selectedPractices === null ? null : selectedPractices.toString(),
            status: template.status == undefined ? "Draft" : template.status
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/campaigns", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getCampaign(): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/campaigns?groupId=" + selectedGroup + "&limit=1000").subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getCampaignById(Id = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/campaigns/details/" + Id.toLocaleLowerCase()).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public delete(Id): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        let data = JSON.stringify({
            campaign_id: Id,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("DELETE", this.url + "/campaigns", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }


    public sendMail(campaign, type = "test", to = ""): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        let data: any;
        if (type == "all") {
            data = JSON.stringify({
                campaign_id: campaign.campaign_id,
                email_type: type,
                user_base: "survey/memberGroup"
            });
        }
        else {
            data = JSON.stringify({
                campaign_id: campaign.campaign_id,
                email_type: type,
                to_email: to
            });
        }
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/campaigns/sendemail", data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getLogo(): Observable<any> {
        let selectedGroup = localStorage.getItem("selectedGroup") || ""
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/GetLogo/v2?type=Group&id=" + selectedGroup).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
}
