<div class="container-fluid">
    <div class="tab">
        <a class="tablinks active">
            <img src="/assets/msx/outreach-white.svg">Patient Outreach
        </a>
        <a [routerLink]="['/members']" class="tablinks">
            <img src="/assets/msx/members.svg">Members & Send Lists
        </a>
        <!-- <a class="tablinks">
            <img src="/assets/msx/members.svg">Members & Send Lists
        </a> -->
    </div>
    <div class="row">
        <div class="input-group col-6 mb-3"></div>
        <div style="display: block; text-align: right" class="input-group col-6 mb-3">
            <button (click)="showTemplate()" class="btn btn-primary ml-1"><img src="/assets/msx/addnew.svg">New
                Campaign</button>
        </div>
    </div>


    <div>
        <div *ngIf="showLoader" class="h-100 d-flex flex-column justify-content-center align-items-center">
            <app-loader></app-loader>
        </div>
        <table class="table-striped datatable">
            <thead>
                <tr>
                    <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns"
                        (sort)="onSort($event)" sortable="{{ c.columnname }}">{{ c.displayname }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of resultList$ | async">
                    <td>{{c.updated_at | date: 'MM-dd-yyyy'}}</td>
                    <td class="text-center">
                        <img (click)="sendTest(c)" class="cursor-pointer" src="/assets/msx/email.svg"
                            alt="Send Test Mail">
                    </td>
                    <td>
                        <span [ngClass]="statusColor(c.status)"
                            class="badge badge-pill badge-primary">{{c.status}}</span>
                    </td>
                    <td>{{c.campaign_name}}</td>
                    <td>{{c.campaign_description}}</td>
                    <td>{{c.total_in_queue}}</td>
                    <td>{{c.total_sent}}</td>
                    <td>{{c.total_opens}}</td>
                    <td>{{c.total_click}}</td>

                    <td class="text-center">
                        <img (click)="onEdit(c)" class="mr-3 cursor-pointer" src="/assets/msx/edit-blue.svg"
                            alt="Edit Campaign">
                        <img (click)="onCopy(c)" class="mr-3 cursor-pointer" src="/assets/msx/duplicate.svg"
                            alt="Copy Campaign">
                        <img (click)="onDelete(c)" class="cursor-pointer" src="/assets/msx/delete-blue.svg"
                            alt="Delete Campaign">
                    </td>
            </tbody>
        </table>
        <div *ngIf="campaign?.length" class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page"
                [pageSize]="tableService.pageSize" [maxSize]="5" [rotate]="true"> </ngb-pagination>

            <select class="custom-select pagination"
                style="width: auto; padding-left: 5px; -webkit-appearance: checkbox !important"
                [(ngModel)]="tableService.pageSize">
                <option [ngValue]="10">10 items per page</option>
                <option [ngValue]="50">50 items per page</option>
                <option [ngValue]="100">100 items per page</option>
            </select>
        </div>

    </div>