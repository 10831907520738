import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TemplateModalComponent } from '../modals/campaign/template-modal/template-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../../../services/campaign.service';
import { SnackbarService } from '../../../../app/shared/snackbar/snackbar.service';
import { TableService } from "../../../services/table.service";
import { SortableHeaderDirective } from '../../../directives/sortable.directive';
import { Observable } from 'rxjs';
import { WarningModalComponent } from '../../../../app/modals/warning-modal/warning-modal.component';
import { Router } from '@angular/router';
import { DuplicateCampaignModalComponent } from '../modals/duplicate-campaign-modal/duplicate-campaign-modal.component';
import { TestMailModalComponent } from '../modals/campaign/test-mail-modal/test-mail-modal.component';
@Component({
  selector: 'app-patient-outreach',
  templateUrl: './patient-outreach.component.html',
  styleUrls: ['./patient-outreach.component.scss']
})
export class PatientOutreachComponent implements OnInit {
  showLoader = false;
  @ViewChild("input") input: ElementRef;
  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;
  resultList$: Observable<any[]>;
  total$: Observable<number>;
  displayedColumns = [
    { displayname: "Last Modified", columnname: "updated_at" },
    { displayname: "Send Test", columnname: "" },
    { displayname: "Status", columnname: "status" },
    { displayname: "Campaign Name", columnname: "campaign_name" },
    { displayname: "Description", columnname: "campaign_name" },
    { displayname: "Queue", columnname: "total_in_queue" },
    { displayname: "Sent", columnname: "total_sent" },
    { displayname: "Opens", columnname: "total_opens" },
    { displayname: "Clicks", columnname: "total_click" },
    { displayname: "Edit/Duplicate/Delete", columnname: "" },
  ];
  constructor(
    private modalService: NgbModal,
    private campaignService: CampaignService,
    private snackbar: SnackbarService,
    public tableService: TableService,
    private router: Router
  ) {
    this.resultList$ = tableService.resultList$;
    this.total$ = tableService.total$;
    this.tableService.searchOnColumn = "";
    this.tableService.pageSize = 10;
    this.tableService.searchTerm = "";
  }
  campaign = [];
  ngOnInit(): void {
    this.getCampaign();
  }

  onSort({ column, direction }: any) {
    // this.paginator.pageIndex = 0;
    this.sorting(column, direction);
  }

  sorting(column, direction) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      } else {
        header.direction = direction;
      }
    });
    this.tableService.sortColumn = column;
    this.tableService.sortDirection = direction;
    this.tableService.page = 1;
  }


  showTemplate() {
    const modalRef = this.modalService.open(TemplateModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {

      }
    });

  }

  getCampaign() {
    this.showLoader = true;
    this.campaignService.getCampaign().subscribe(
      (data) => {
        this.campaign = data[0].attributes.list;
        this.tableService.DATA = this.campaign;
        this.tableService.page = 1;
        this.showLoader = false;
        this.sorting("updated_at", "desc");
      },
      (err) => {
        this.showLoader = false;
        this.snackbar.show(err[0].detail, "danger");
      }
    );
  }

  onDelete(campaign) {
    const modalRef = this.modalService.open(WarningModalComponent, { backdrop: "static" });
    modalRef.componentInstance.Message = "Are you sure you want to delete?";
    modalRef.componentInstance.OKButton = "Delete";
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.campaignService.delete(campaign.campaign_id).subscribe(
          (data) => {
            this.snackbar.show("Campaign deleted successfully.", "success");
            this.getCampaign();
          },
          (err) => {
            //this.showLoader = false;
            this.snackbar.show(err[0].detail, "danger");
          }
        );
      }
    });
  }

  onEdit(campaign) {
    this.router.navigate(["campaign"], { queryParams: { id: campaign.campaign_id } });
  }

  onCopy(campaign) {
    const modalRef = this.modalService.open(DuplicateCampaignModalComponent, { backdrop: "static" });
    modalRef.componentInstance.campaignId = campaign.campaign_id;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {
        this.getCampaign();
      }
    });

  }

  sendTest(campaign) {
    const modalRef = this.modalService.open(TestMailModalComponent, { backdrop: "static" });
    modalRef.componentInstance.campaignId = campaign.campaign_id;
    modalRef.componentInstance.Response.subscribe((receivedEntry) => {
      if (receivedEntry.proceed) {

      }
    });


  }

  public statusColor(status = "") {
    let className = ""
    if (status.toLocaleLowerCase() == "schedule" || status.toLocaleLowerCase() == "scheduled") {
      return "scheduleCampaign";
    }
    if (status.toLocaleLowerCase() == "draft") {
      return "draftCampaign";
    }
    if (status.toLocaleLowerCase() == "sent") {
      return "sentCampaign";
    }
    if (status.toLocaleLowerCase() == "sending") {
      return "sendingCampaign";
    }
    else {
      return "defaultCampaign";
    }
  }

}
